export enum EPipelineTimestampsNamesByKey {
  submitJob = 'Submit job',
  preprocessorPreprocessingConsumerStarted = '[Preprocessor] started',
  preprocessorDownloadingFilesStarted = '[Preprocessor] downloading files started',
  preprocessorFilesSentToDolbyApiDiagnosis = '[Preprocessor] files successfully sent to dolby api diagnosis',
  preprocessorFfmpegProcessingStarted = '[Preprocessor] ffmpeg started',
  preprocessorUploadOutputWavStarted = '[Preprocessor] started uploading wav',
  preprocessorUploadOutputMp3Started = '[Preprocessor] started uploading mp3',
  preprocessorCleanTemporaryFilesStarted = '[Preprocessor] cleaning files',
  preprocessorPreprocessingFinished = '[Preprocessor] finished',
  preprocessorSentToNextService = '[Preprocessor] sent to next service',
  dolbyDiagnosisStarted = '[Dolby] sent to dolby api diagnosis',
  dolbyEnhancementStarted = '[Dolby] sent to dolby api enhancement',
  submitterStartedProcessing = '[Submitter] started processing',
  submitterGotResultFromTranscriptService = '[Submitter] got result with transcription',
  sliceSegmentsStarted = '[Slicer] slicing to segments started',
  reTranscribeSegmentsStarted = '[Whisper] transcribing each segment started',
  llmTranscriptCreationStarted = '[LLM] transcript creation started',
  submitterSentToNextService = '[Submitter] finished and sent to next service',
}
